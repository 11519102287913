import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/congratulations",
    name: "Congratulations",
    component: () => import("../views/CongratulationsView.vue"),
  },
  {
    path: "/plans",
    name: "Plans",
    component: () => import("../views/PlansView.vue"),
  },
  {
    path: "/my_qr_codes",
    name: "MyQrCodes",
    component: () => import("../views/manager/QrCodeList.vue"),
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    component: () => import("../views/manager/DashBoardView.vue"),
  },
  {
    path: "/my_account",
    name: "MyAccount",
    component: () => import("../views/manager/CustomerView.vue"),
  },
  {
    path: "/QrCode/:id?",
    name: "CreateEditQrCode",
    component: () => import("../views/manager/CreateEditQrCode.vue"),
    beforeEnter: (to,from,next) => {
      if (!to.params.id) {
        to.params.id = null
      }
      next();
    }
  },
  {
    path: "/my_plan",
    name: "MyPlan",
    component: () => import("../views/manager/MyPlan.vue")
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/terms",
    name: "Terms&Conditions",
    component: () => import("../views/TermsConditions.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue")
  },
  {
    path: "/:id",
    name: "Redirect",
    component: () => import("../views/RedirectView.vue"),
  },
  {
    path: "/not_found",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    path: "/billing",
    name: "Billing",
    component: () => import("../views/BillingView.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/ErrorView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
