import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      session: undefined,
    };
  },
  mutations: {
    setSession(state, payload = null) {
      if (payload) state.session = payload;
      else state.session = undefined;
    },
  },
  getters: {
    sessionActive(state) {
      if (!state.session) {
        return false;
      } else {
        return state.session.active;
      }
    },
    jwt_token(state) {
      if (!state.session) {
        return "";
      } else {
        return state.session.jwt_token;
      }
    },
    customerId(state) {
      if (!state.session) {
        return "";
      } else {
        return state.session.session_data.customer.id;
      }
    },
    customer(state) {
      if (!state.session) {
        return false;
      } else {
        return state.session.session_data.customer;
      }
    },
    user(state) {
      if (!state.session) {
        return false;
      } else {
        return state.session.session_data.user;
      }
    },
    admin(state) {
      if (!state.session) {
        return false;
      } else {
        return state.session.session_data.user.admin;
      }
    },
  },
  actions: {},
  modules: {},
});
