<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="iconViewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      v-if="selectedIconContent"
      v-html="selectedIconContent"
    />
  </svg>
</template>
  
  <script>
  export default {
    props: {
      selectedIcon: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        iconWidth: '23',
        iconHeight: '22',
        iconViewBox: '0 0 23 22',
        selectedIconContent: '',
      };
    },
    watch: {
      selectedIcon: 'loadIcon',
    },
    created() {
      this.loadIcon();
    },
    methods: {
      loadIcon() {
        const svgPath = `assets/SVG/${this.selectedIcon}.svg`;
  
        fetch(svgPath)
          .then((response) => response.text())
          .then((svgContent) => {
            this.parseSVGContent(svgContent);
          })
          .catch((error) => {
            console.error(`Erro ao carregar o SVG: ${error}`);
          });
      },
      parseSVGContent(svgContent) {
        // Parse do conteúdo do SVG para extrair largura, altura e viewBox
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = doc.documentElement;
  
        // Atualiza os dados do componente com os valores do SVG
        this.iconWidth = svgElement.getAttribute('width') || this.iconWidth;
        this.iconHeight = svgElement.getAttribute('height') || this.iconHeight;
        this.iconViewBox = svgElement.getAttribute('viewBox') || this.iconViewBox;
  
        // Atualiza o conteúdo do SVG
        this.selectedIconContent = svgContent;
      },
    },
  };
  </script>