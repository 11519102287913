import "@fortawesome/fontawesome-free/css/all.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from "vuetify/iconsets/fa";
import colors from "vuetify/lib/util/colors";
import { VuetifyDateAdapter } from "vuetify/labs/date/adapters/vuetify";

console.log(colors);

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
  date: {
    adapter: VuetifyDateAdapter,
  },
  theme: {
    defaultTheme: "light",
    /*themes: {
      light: {
        dark: false,
        colors: {
          background: colors.shades.black,
          surface: colors.shades.black,
          primary: colors.orange.darken2,
          "primary-darken-1": colors.orange.darken4,
          secondary: colors.orange.lighten1,
          "secondary-darken-1": colors.orange.lighten5,
          error: colors.red.darken1,
          info: colors.orange.accent2,
          success: colors.lime.lighten4,
          warning: colors.deepPurple.lighten4,
        },
      },
    },*/
  },
});
