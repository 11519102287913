<template>
  <v-app class="bg-transparent">
    <!--
      Barra do topo da página com botões de acesso rápido
    -->
    <v-toolbar
      v-if="$route.name != 'Redirect'"
      elevation="0"
      style="box-shadow: 0px 5px 85px -40px rgba(0, 0, 0, 0.20) !important; margin-block: 8px !important;"
      color="transparent"
      flat
      extended
      extension-height="10"
    >
      <v-img 
        src="./assets/logo.png" 
        max-height="68"
        max-width="150"
        inline
        @click="$router.push('/')"
      />
      <v-spacer />
      <v-toolbar-items
        v-if="!$store.getters.sessionActive"
        class="d-flex align-center"
      >
        <v-btn
          to="/plans"
          variant="plain"
          :class="$route.path === '/plans' && !loginDialog ? 'activeButton' : 'inactiveButton'"
        >
          Planos
        </v-btn>
        <v-btn
          variant="plain"
          :class="loginDialog ? 'activeButton' : 'inactiveButton'"
          @click="loginDialog = true"
        >
          Entrar
        </v-btn>
        <v-btn
          class="singUpButton"
          text="Cadastre-se"
          @click="createAccountDialog = true"
        />
      </v-toolbar-items>
      <v-toolbar-items
        v-if="$store.getters.sessionActive"
        class="d-flex align-center"
      >
        <v-btn
          to="/dashboard"
          variant="plain"
          :class="$route.path === '/dashboard' ? 'activeButton' : 'inactiveButton'"
        >
          Dashboard
        </v-btn>
        <v-btn
          to="/my_qr_codes"
          variant="plain"
          :class="$route.path === '/my_qr_codes' ? 'activeButton' : 'inactiveButton'"
        >
          Meus QR Codes
        </v-btn>
        <!--
          <v-btn>
            Análises
          </v-btn>
        -->
        <v-row
          class="ml-8"
          justify="center"
          align="center"
        >
          <v-icon
            class="mr-4"
            color="black"
            icon="far fa-user"
          />
          <v-row no-gutters>
            Olá,
          </v-row>
          <v-row
            class="boldText mx-2"
            no-gutters
          >
            {{ getUserName }}
          </v-row>
        </v-row>
      </v-toolbar-items>
      <!--
        Menu suspenso ao clicar no nav-icon
      -->
      <v-menu
        location="center"
        min-height="225"
        min-width="362"
      >
        <template #activator="{props}">
          <v-app-bar-nav-icon
            color="#323232"
            class="ml-4"
            v-bind="props"
          />
        </template>
        <v-list class="menuCard">
          <v-list-item-title class="mt-2 ml-6 mr-2">
            <v-row
              align="center"
              no-gutters
            >
              <v-col class="ma-0 pa-0">
                <span class="menuTitle">Menu Rápido</span>
              </v-col>
              <v-spacer />
              <v-col class="d-flex justify-end ma-0 pa-0">
                <v-btn
                  variant="plain"
                  icon="fas fa-close"
                />
              </v-col>
            </v-row>
          </v-list-item-title>
          <!--
            Lista de item quando usuário esta deslogado
          -->
          <v-list-item
            v-if="!$store.getters.sessionActive"
            to="/plans"
            class="menuOptions"
            color="#ff3d00"
          >
            Planos & Recursos
          </v-list-item>
          <v-divider class="mx-6" />
          <v-list-item
            v-if="!$store.getters.sessionActive"
            to="/about"
            class="menuOptions"
            color="#ff3d00"
          >
            FAQ
          </v-list-item>
          <v-divider class="mx-6" />
          <v-list-item
            v-if="!$store.getters.sessionActive"
            class="menuOptions"
            color="#ff3d00"
            to="/contact"
          >
            Contato
          </v-list-item>
          <!--
            Lista de item quando usuário esta logado
          -->
          <v-list-item
            v-if="$store.getters.sessionActive"
            to="/my_account"
            class="menuOptions"
            color="#ff3d00"
          >
            Minha Conta
          </v-list-item>
          <v-divider
            v-if="$store.getters.sessionActive"
            class="mx-6"
          />
          <v-list-item
            v-if="$store.getters.sessionActive"
            to="/my_plan"
            class="menuOptions"
            color="#ff3d00"
          >
            Meu Plano
          </v-list-item>
          <v-divider
            v-if="$store.getters.sessionActive"
            class="mx-6"
          />
          <v-list-item
            v-if="$store.getters.sessionActive"
            class="menuOptions"
            color="#ff3d00"
            @click="logout()"
          >
            Sair
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!--
      Main content 
    -->
    <v-main class="bg-transparent">
      <router-view
        @open_login_dialog="handleOpenLoginDialog"
        @open_create_dialog="handleCreateAccountDialog"
      />
      <v-dialog v-model="payment">
        <v-card 
          max-width="50%" 
          class="mx-auto"
        >
          <v-card-text>
            <div id="paymentBrick_container" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
    <!--
      Footer com todas as informações e acessos rapidos
    -->
    <v-footer
      v-if="$route.name != 'Redirect'"
      color="#101010"
      class="d-flex flex-column pa-0 ma-0"
    >
      <v-col>
        <v-row
          class="footerOptions"
          justify="center"
        >
          <v-col cols="10">
            <v-row class="d-flex flex-row">
              <v-col class="d-flex flex-column mr-4">
                <span class="footerTitle">Acesso Rápido</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/'), toTopPage()"
                >Página Inicial</span>
                <span
                  class="footerCommonTextLink"
                  @click="scrollTo('aboutQRCode')"
                >Sobre</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/plans'), toTopPage()"
                >Planos</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/about'), toTopPage()"
                >FAQ</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/contact'), toTopPage()"
                >Contato</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/terms'), toTopPage()"
                >Política de Privacidade</span>
              </v-col>
              <v-col
                v-if="!$store.getters.sessionActive"
                class="d-flex flex-column mr-4"
              >
                <span class="footerTitle">Minha Conta</span>
                <span
                  class="footerCommonTextLink"
                  @click="loginDialog = true, toTopPage()"
                >Entrar</span>
                <span
                  class="footerCommonTextLink"
                  @click="createAccountDialog = true, toTopPage()"
                >Cadastre-se</span>
              </v-col>
              <v-col
                v-else
                class="d-flex flex-column mr-4"
              >
                <span class="footerTitle">Minha Conta</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/dashboard'), toTopPage()"
                >Dashboard</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/my_qr_codes'), toTopPage()"
                >Meus QR Codes</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/my_account'), toTopPage()"
                >Minha Conta</span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/my_plan'), toTopPage()"
                >Meu Plano</span>
              </v-col>
              <v-col class="d-flex flex-column mr-4">
                <span class="footerTitle">Contato</span>
                <span class="footerCommonText">Alameda Rio Negro, 1030 – Alphaville
                  São Paulo 06454-000 | 6° andar</span>
                <span class="footerCommonText">
                  Brasil: <span class="footerCommonTextBold">+55 11 94532-2930</span>
                  <p>Tel.: <span class="footerCommonTextBold">+55 11 2724-7564</span></p>
                </span>
                <span
                  class="footerCommonTextLink"
                  @click="$router.push('/contact'), toTopPage()"
                >contato@qrxcode.net</span>
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="footerTitle">Parceiros</span>
                <v-row no-gutters>
                  <v-img @click="openStore('ARX')">
                    <template #default>
                      <img
                        src="https://arxexperience.com/wp-content/uploads/2021/07/logo-g.png"
                        class="arxLogo"
                      >
                    </template>
                  </v-img>
                </v-row>
                <v-row
                  no-gutters
                  class="footerTitle"
                >
                  Baixe nosso APP
                </v-row>
                <v-row no-gutters>
                  <v-img
                    class="mr-2"
                    @click="openStore('google')"
                  >
                    <template #default>
                      <img
                        src="assets/selo_google_play.png"
                        class="stamp"
                      >
                    </template>
                  </v-img>
                  <v-img>
                    <template #default>
                      <img
                        src="assets/selo_apple_store.png"
                        class="stamp"
                      >
                    </template>
                  </v-img>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col style="background-color: #020202">
        <v-row
          class="my-2"
          justify="center"
        >
          <v-col cols="10">
            <v-row align="center">
              <v-col cols="7">
                <span class="footerCommonText">QRx Code  ©2023 - Todos os Direitos Reservados</span>
              </v-col>
              <v-col align="end">
                <v-icon
                  color="#FF6433"
                  class="footerIcons"
                  size="small"
                >
                  fab fa-linkedin
                </v-icon>
                <v-icon
                  color="#FF6433"
                  class="footerIcons"
                  size="small"
                >
                  fab fa-facebook-f
                </v-icon>
                <v-icon
                  color="#FF6433"
                  class="footerIcons"
                  size="small"
                >
                  fab fa-youtube
                </v-icon>
                <v-icon
                  color="#FF6433"
                  class="footerIcons"
                  size="small"
                >
                  fab fa-instagram
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-footer>
    <!--
      Caixa de login
    -->
    <v-dialog
      v-model="loginDialog"
      max-width="520"
    >
      <v-card
        class="dialogCard"
      >
        <v-card-title
          class="dialogBoldText d-flex flex-column"
        >
          <v-row
            justify="end"
            align="end"
          >
            <v-icon
              size="24"
              @click="loginDialog = false"
            >
              fas fa-close
            </v-icon>
          </v-row>
          <v-row
            class="ma-0"
            justify="center"
            align="center"
          >
            <SVGLoader
              selected-icon="circles"
              class="mr-4"
            />
            Login
          </v-row>
        </v-card-title>
        <v-card-text
          class="d-flex flex-column align-center"
        >
          <v-col
            justify="center"
            align="center"
            class="pa-0"
          >
            <v-form
              v-model="loginAccountForm"
              fast-fail
              @submit.prevent="login"
            >
              <v-col
                cols="10"
                class="pa-0 d-flex flex-column"
              >
                <span
                  class="commonText mb-2"
                  align="start"
                >
                  E-mail
                </span>
                <v-text-field
                  v-model="username"
                  autofocus
                  variant="solo"
                  density="compact"
                  flat
                  class="textField pa-1"
                  :rules="[
                    () => !!username || 'Este campo é obrigatório',
                    () =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(username) ||
                      'E-mail deve ser valido',
                  ]"
                  :error-messages="errorMessages"
                  required
                  :hide-details="!hideLoginEmailDetails"
                >
                  <template #prepend-inner>
                    <v-icon
                      color="#FF6433"
                      style="opacity: 1 !important;"
                    >
                      fas fa-envelope
                    </v-icon>
                  </template>
                </v-text-field>
                <span
                  class="commonText mt-4 mb-2"
                  align="start"
                >
                  Senha
                </span>
                <v-text-field
                  v-model="password"
                  :type="showPassword ? '' : 'password'"
                  :rules="[rules.required, rules.min]"
                  variant="solo"
                  density="compact"
                  flat
                  class="textField pa-1 mb-5"
                  :hide-details="hideLoginPasswordDetails"
                >
                  <template #prepend-inner>
                    <v-icon
                      color="#FF6433"
                      style="opacity: 1 !important;"
                    >
                      fas fa-lock
                    </v-icon>
                  </template>
                  <template #append-inner>
                    <v-icon
                      color="#FF6433"
                      style="opacity: 1 !important;"
                      @click="showPassword = !showPassword"
                    >
                      {{ !showPassword ? 'fas fa-eye-slash' : 'fas fa-eye' }}
                    </v-icon>
                  </template>
                </v-text-field>
                <span
                  align="center"
                  class="linkedText"
                  @click="loginDialog = false, forgotPasswordDialog = true"
                >Esqueceu a senha?</span>
                <v-btn
                  :disabled="!loginAccountForm"
                  class="loginButton"
                  @click="login"
                >
                  Entrar
                </v-btn>
                <span
                  class="commonText mt-6 mb-6"
                  align="center"
                >Você não tem uma conta? <span
                  class="linkedText"
                  @click="loginDialog = false, createAccountDialog = true"
                >Clique aqui e crie</span></span>
              </v-col>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
      <v-snackbar
        v-model="showMessage"
        :timeout="5000"
      >
        {{ message }}
      </v-snackbar>
    </v-dialog>
    <!--
      Caixa para enviar e-mail de redefinir senha
    -->
    <v-dialog
      v-model="forgotPasswordDialog"
      max-width="520"
    >
      <v-card class="dialogCard">
        <v-card-title class="dialogBoldText d-flex flex-column">
          <v-row
            justify="end"
            align="end"
          >
            <v-icon
              size="24"
              @click="forgotPasswordDialog = false"
            >
              fas fa-close
            </v-icon>
          </v-row>
          <v-row
            justify="center"
            align="start"
            class="mr-3"
          >
            <SVGLoader
              selected-icon="circles"
              class="mr-5 mt-2"
            />
            <span>Esqueci minha <p>senha</p></span>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center">
          <v-col
            cols="10"
            class="pa-0 mx-4 d-flex flex-column justify-center align-center"
          >
            <span class="commonText mb-4 text-left">Digite seu endereço de e-mail e lhe enviaremos 
              uma nova senha.</span>
            <v-form
              v-model="forgetPassform"
              fast-fail
              @submit.prevent
            >
              <v-col
                class="pa-0 mt-4"
                align="start"
              >
                <span class="commonText mt-4 text-left">E-mail</span>
                <v-text-field
                  v-model="userEmail"
                  variant="solo"
                  density="compact"
                  flat
                  class="textField pa-1 mb-5 mt-2"
                  :rules="[
                    () => !!userEmail || 'Este campo é obrigatório',
                    () =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail) ||
                      'E-mail deve ser valido',
                  ]"
                  :error-messages="errorMessages"
                  required
                  :hide-details="hideForgetPasswordDetails"
                >
                  <template #prepend-inner>
                    <v-icon
                      color="#FF6433"
                      style="opacity: 1 !important;"
                    >
                      fas fa-envelope
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-btn
                :disabled="!forgetPassform"
                class="loginButton my-4"
              >
                Enviar
              </v-btn>
            </v-form>
            <span
              class="linkedText my-4"
              @click="forgotPasswordDialog = false, loginDialog = true"
            >Voltar para o login</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--
      Caixa para criar conta
    -->
    <v-dialog
      v-model="createAccountDialog"
      max-width="520"
      :scrollable="true"
      scroll-strategy="none"
    >
      <v-card class="dialogCard">
        <v-card-title class="dialogBoldText d-flex flex-column">
          <v-row
            justify="end"
            align="center"
          >
            <v-icon
              size="24"
              class="ml-8"
              @click="createAccountDialog = false"
            >
              fas fa-close
            </v-icon>
          </v-row>
          <v-row
            justify="center"
            align="center"
          >
            <SVGLoader
              selected-icon="circles"
              class="mr-5 mt-2"
            />
            <span>Cadastre-se</span>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center">
          <v-form
            v-model="newAccountForm"
            fast-fail
            @submit.prevent="enviar"
          >
            <v-col
              cols="10"
              class="pa-0 mx-4 d-flex flex-column justify-center"
            >
              <span class="commonText text-left">Nome completo</span>
              <v-text-field
                ref="full_name"
                v-model="user.full_name"
                variant="solo"
                density="compact"
                flat
                class="textField pa-1 mb-5 mt-2"
                :rules="[() => !!user.full_name || 'Este campo é obrigatório', rules.minName]"
                :error-messages="errorMessages"
                required
                hide-details
              />
              <span class="commonText text-left">E-mail</span>
              <v-text-field
                ref="email"
                v-model="user.email"
                type="email"
                variant="solo"
                density="compact"
                flat
                class="textField pa-1 mb-5 mt-2"
                :rules="[
                  () => !!user.email || 'Este campo é obrigatório',
                  () =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user.email) ||
                    'E-mail deve ser valido',
                ]"
                :error-messages="errorMessages"
                required
                :hide-details="hideCreateAccountEmailDetails"
              />
              <v-row>
                <v-col class="d-flex flex-column">
                  <span class="commonText text-left">Senha</span>
                  <v-text-field
                    v-model="user.password"
                    :rules="[rules.required, rules.min]"
                    type="password"
                    class="textField pa-1 mt-2"
                    variant="solo"
                    flat
                    density="compact"
                    :error-messages="errorMessages"
                    required
                    :hide-details="hideCreateAccountPassDetails"
                  />
                </v-col>
                <v-col class="d-flex flex-column">
                  <span class="commonText text-left">Confirmar Senha</span>
                  <v-text-field
                    v-model="user.confirmPass"
                    :rules="[rules.required, rules.samePass(user.confirmPass,user.password)]"
                    type="password"
                    class="textField pa-1 mt-2"
                    variant="solo"
                    flat
                    density="compact"
                    :error-messages="errorMessages"
                    required
                    :hide-details="hideCreateAccountConfirmPassDetails"
                  />
                </v-col>
              </v-row>
              <v-switch
                v-model="enterprise"
                variant="outlined"
                :label="`Pessoa ${enterprise ? 'Juridica' : 'física'}`"
                inset
                color="#FF6433"
                hide-details
                flat
              />
              <span
                v-if="enterprise"
                class="commonText text-left"
              >Razão Social</span>
              <v-text-field
                v-if="enterprise"
                ref="customer_full_name"
                v-model="customer.full_name"
                variant="solo"
                density="compact"
                flat
                class="textField pa-1 mb-5 mt-2"
                :rules="[() => !!customer.full_name || 'Este campo é obrigatório', rules.minName]"
                :error-messages="errorMessages"
                required
                :hide-details="hideDetailsFullName"
              />
              <span
                v-if="enterprise"
                class="commonText text-left"
              >Nome Fantasia</span>
              <v-text-field
                v-if="enterprise"
                ref="customer_short_name"
                v-model="customer.short_name"
                variant="solo"
                density="compact"
                flat
                class="textField pa-1 mb-5 mt-2"
                :rules="[() => !!customer.short_name || 'Este campo é obrigatório', rules.minName]"
                :error-messages="errorMessages"
                required
                :hide-details="hideDetailsShortName"
              />
              <span
                v-if="enterprise"
                class="commonText text-left"
              >CNPJ</span>
              <v-text-field
                v-if="enterprise"
                ref="customer_document"
                v-model="customer.document"
                variant="solo"
                density="compact"
                flat
                class="textField pa-1 mb-5 mt-2"
                :rules="[() => !!customer.document || 'Este campo é obrigatório', rules.checkCNPJ]"
                :error-messages="errorMessages"
                required
                :hide-details="hideDetailsDocument"
                @update:model-value="formatCNPJInput(customer.document)"
              />
              <v-checkbox
                v-model="accordance"
                hide-details
                density="compact"
                color="#FF6433"
              >
                <template #label>
                  <span class="readConditionText ml-2">Li e concordo com os <span
                    class="linkedConditionText"
                    @click="$router.push('/terms'), createAccountDialog = false"
                  >termos de uso</span> e <span
                    class="linkedConditionText"
                    @click="$router.push('/terms'), createAccountDialog = false"
                  >políticas de 
                    privacidade</span></span>
                </template>
              </v-checkbox>
              <v-btn
                :disabled="!accordance || !newAccountForm"
                class="loginButton mt-4 mb-8"
                @click="enviar(), createAccountDialog = false"
              >
                Cadastrar
              </v-btn>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { loadMercadoPago } from "@mercadopago/sdk-js";
import SVGLoader from "@/components/vue/SVGLoader.vue"
import axios from "axios";


await loadMercadoPago();

const url = `${process.env.VUE_APP_AUTHENTICATION_API}`;

  export default {
    name: "App",
    components: {
      SVGLoader,
    },
    data: () => ({
      api_root: process.env.VUE_APP_ADMINISTRATION_API,
      payment: false,
      bricksBuilder: null,
      username: "", // Variavel que armazena o login do usuario para tela de login
      password: "", // Variavel que armazena a senha do usuario para tela de login
      showPassword: false, // Controlador para visualizar senha digitada
      showMessage: false, // Controlador da mensagem que vai informar caso não consiga efetuar o login
      message: "", // Mensagem que vai informar caso não consiga efetuar o login
      userEmail: "", // Variavel que armazena o e-mail para redefinir a senha
      loginDialog: false, // Controlador do dialog para login
      forgotPasswordDialog: false, // Controlador do dialog para trocar senha
      createAccountDialog: false, // Controlador do dialog para criar conta
      enterprise: false, // Controlador para definição de tipo de conta CNPJ ou PF
      accordance: false, // Controlador para indicar se o usuario aceitou os termos e condições
      loginAccountForm: false, // Controlador do form para janela de login
      newAccountForm: false, // Controlador do form para janela de nova conta
      forgetPassform: false, // Controlador do form para janela de esqueceu a senha
      user: {
        email: "",
        full_name: "",
        short_name: "",
        password: "",
        confirmPass: "",
        active: true,
      },
      customer: {
        full_name: "",
        short_name: "",
        document: "",
        logo: "",
        active: true,
      },
      rules: {
        required: (value) => !!value || "Este campo é obrigatório",
        min: (value) => value.length >= 8 || "Min. 8 caracteres",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "E-mail deve ser valido",
        check: () => {
          console.log(this.user)
        },
        minName: (value) => value.length >= 3 || "Min. 3 caracteres",
        checkCNPJ: (value) => value.length >= 18 || "CNPJ Inválido",
        samePass: (value, password) => value === password || "Senhas não coincidem."
      },
    }),
    computed: {
      sessionActive() {
        if (this) {
          return this.$store.getters.sessionActive;
        } else {
          return false;
        }
      },
      getUserName() {
        const name = this.$store.getters.customer;
        return name.name;
      },
      hideLoginEmailDetails () {
        const valid =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.$data.username);
        return (!valid && this.$data.username) || false;
      },
      hideLoginPasswordDetails () {
        return (this.$data.password.length >= 8 || this.$data.password === "") || false;
      },
      hideForgetPasswordDetails () {
        const valid =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.$data.userEmail);
        return (valid || !this.$data.userEmail) || false;
      },
      hideCreateAccountEmailDetails () {
        const valid =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.$data.user.email);
        return (valid || !this.$data.user.email) || false;
      },
      hideCreateAccountPassDetails () {
        return (this.$data.user.password.length >= 8 || this.$data.user.password === "") || false;
      },
      hideCreateAccountConfirmPassDetails () {
        return (this.$data.user.confirmPass.length <= 8 && this.$data.user.confirmPass === this.$data.user.password) || false;
      },
      hideDetailsFullName () {
        return (this.$data.customer.full_name === "" || this.$data.customer.full_name.length >= 3) || false
      },
      hideDetailsShortName () {
        return (this.$data.customer.short_name === "" || this.$data.customer.short_name.length >= 3) || false
      },
      hideDetailsDocument () {
        return (this.$data.customer.document === "" || this.$data.customer.document.length === 18) || false
      }
    },
    mounted() {
      /*if (this.sessionActive) {
        this.$data.payment = true;
        const mp = new window.MercadoPago("TEST-763dc405-4824-4d89-8216-41ebf4384962", {
          locale:"pt-BR",
        })
        const bricksBuilder = mp.bricks();
        this.renderPaymentBrick(bricksBuilder)
        
      }*/
      // if(this.$route.name == 'Redirect') alert('redirect')
      window.addEventListener("resize", () => {
        console.log(this.$vuetify.display.name)
      })
    },
    methods: {
      verifySignature() {
        if (this.$store.getters.sessionActive) {
          //
        }
      },
      async renderPaymentBrick(bricksBuilder){
        const settings = {
          initialization: {
            amount: 100,
            payer: {
              email: 'clodoaldo@lab4tech.com.br',
            }
          },
          style: {
            theme: 'default'
          },
          customization: {
            visual: {
              hidePaymentButton: true,
              style: {
                theme: "default",
              },
            },
            paymentMethods: {
              creditCard: "all",
                      debitCard: "all",
                      atm: "all",
              maxInstallments: 1
            },
          },
          callbacks: {
            onReady: () => {
              // calback quando o brick estiver pronto
            },
            onSubmit: ({ paymentType, formData }) => {
              return new Promise((resolve, reject) => {
                fetch("/processar-pago", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(formData)
                }).then((response) => {
                  resolve();
                }).catch((error) => {
                  reject();
                })
              })
            },
            onError: (error) => {
              console.log(error);
            }
          }
        };
        await bricksBuilder.create("payment", "paymentBrick_container", settings);
      },
      logout() {
        this.$store.commit("setSession");
        this.empty();
        this.$router.push("/");
      },
      toTopPage(){
        window.scrollTo({top: 0, behavior: 'smooth'});
      },
      login() {
        axios
          .post(
            `${url}/login`,
            {
              email: this.$data.username,
              password: this.$data.password,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          )
          .then(async (response) => {
            await this.$store.commit("setSession", response.data.authentication_data);
            this.$data.loginDialog = false;
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                this.message = "Ocorreu um erro inexperado! Tente novamente mais tarde!";
              } else {
                this.message = error.response.data.message;
              }
            } else {
              this.message="Ocorreu um erro inexperado! Tente novamente mais tarde!";
            }
            this.showMessage = true;
          });
          this.empty();
      },
      enviar() {
        const address = `${url}/register`;
        if (!this.$data.enterprise) {
          this.$data.customer.document = "FÍSICA";
          this.$data.customer.full_name = this.user.full_name;
          this.$data.customer.short_name = this.user.short_name;
        }
        axios
          .post(
            address,
            {
              user: this.$data.user,
              customer: this.$data.customer,
              recurrence_id: this.$data.selected_recurrence_id,
              signature_model_id: this.$data.selected_model_id
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          )
          .then((response) => {
            this.$router.push("/congratulations");
          })
          .catch((error) => {
            if (error.response.status == "500")
              this.$data.message =
                "Não foi possivel completar seu registro! Tente novamente mais tarde!";
            else
              this.$data.message =
                "Aparentemente seu registro já foi realizado. Tente efetuar a troca de senha!";
            this.$data.showMessage = true;
          });
          this.empty();
      },
      handleOpenLoginDialog() {
        this.loginDialog = true;
      },
      handleCreateAccountDialog() {
        this.createAccountDialog = true;
      },
      formatCNPJInput(input) {
        // Remove caracteres não numéricos
        let number = input.replace(/\D/g, '');

        if (number.length > 14) {
          // Se exceder, truncar para os primeiros 14 dígitos
          number = number.slice(0, 14);
        }

        // Formatar para CNPJ
        if (number.length <= 2) {
          input = number;
        } else if (number.length <= 5) {
          input = `${number.slice(0, 2)}.${number.slice(2)}`;
        } else if (number.length <= 8) {
          input = `${number.slice(0, 2)}.${number.slice(2, 5)}.${number.slice(5)}`;
        } else if (number.length <= 12) {
          input = `${number.slice(0, 2)}.${number.slice(2, 5)}.${number.slice(5, 8)}/${number.slice(8)}`;
        } else {
          input = `${number.slice(0, 2)}.${number.slice(2, 5)}.${number.slice(5, 8)}/${number.slice(8, 12)}-${number.slice(12)}`;
        }
        this.$data.customer.document = input
      },
      empty() {
        this.$data.username= "";
        this.$data.password= "";
        this.$data.user= {
          email: "",
          full_name: "",
          short_name: "",
          password: "",
          confirmPass: "",
          active: true,
        };
        this.$data.customer= {
          full_name: "",
          short_name: "",
          document: "",
          logo: "",
          active: true,
        };
      },
      openStore(store) {
        switch (store) {
          case "google":
            window.open("https://play.google.com/store/apps/details?id=com.arxexperience.qrxcode", '_blank', 'noreferrer');
            break;
          
          case "ARX":
            window.open("https://arxexperience.com/", '_blank', 'noreferrer');
            break;

        }
      },
      scrollTo(id) {
        const el = document.getElementById(id);
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    },
  };
</script>

<style scoped>
/* CSS com a fonte padrão de toda pagina */
body {
  font-family: 'Montserrat', sans-serif;
}
html {
  scroll-behavior: smooth;
}
/* CSS com o estilo da pagina principal */
#main {
  background: url('assets/background.png') no-repeat center center fixed !important;
  background-size: auto;
}
/* CSS com o estilo do botão para cadastrar-se */
.singUpButton {
  border-radius: 17px !important;
  background: linear-gradient(117deg, #FF6433 -20.92%, #FFB44E 175.18%) !important;
  box-shadow: 0px 4px 15px -4px #CBAFA2 !important;
  color: #FFF !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-left: 20px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  height: 44px !important;
}
/* CSS com o estilo do card do menu */
.menuCard {
  border-radius: 20px !important;
  background: #FFF !important;
  box-shadow: 0px 0px 114px 0px rgba(0, 0, 0, 0.20) !important;
}
/* CSS com o estilo da fonte do menu em negrito */
.menuTitle {
  color: #323232;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}
/* CSS com o estilo da fonte do menu normal */
.menuOptions {
  color: #323232;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-right: 20px;
}
/* CSS com o estilo do botão ativo */
.activeButton{
  padding-right: 20px !important;
  color: #FF6433 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  text-decoration-thickness: 2px !important;
  text-decoration-line: underline !important;
  opacity: 1 !important;
}
/* CSS com o estilo do botão inativo */
.inactiveButton{
  padding-right: 20px !important;
  color: #323232 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  opacity: 1 !important;
}
/* CSS com o estilo da linha para as opções do Footer */
.footerOptions{
  display: flex;
  flex-direction: row;
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
/* CSS com o estilo o titulo das opções do Footer */
.footerTitle{
  color: #FFF;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
}
/* CSS com o estilo dos textos do footer */
.footerCommonText{
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
/* CSS com o estilo dos textos do footer em negreito*/
.footerCommonTextBold{
  color:#FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
/* CSS com o estilo dos textos que podem ser selecionados no footer */
.footerCommonTextLink{
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
/* CSS com o estilo dos textos ao passar o mouse que podem ser selecionados no footer */
.footerCommonTextLink:hover{
  color: #FF6433;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  text-decoration: underline;
}
/* CSS com o estilo dos icones do footer */
.footerIcons{
  margin-right: 16px;
  padding: 18px;
  border-radius: 50px;
  border-color: #FF6433;
  border-style: solid;
  border-width: 3.5px;
}
/* CSS com o estilo do card para login */
.dialogCard{
  border-radius: 40px !important;
  background: #FFF !important;
  box-shadow: 0px 0px 114px 0px rgba(0, 0, 0, 0.20) !important;
  padding-inline: 25px;
  padding-block: 10px;
}
/* CSS com o estilo do texto de login */
.dialogBoldText{
  display: flex;
  flex-direction: row;
  color: #323232;
  font-family: Montserrat;
  font-size: 46px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px !important;
  margin-top: 40px;
  margin-bottom: 30px;
}
/* CSS com o estilo para os textos comum */
.commonText{
  color: #323232;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.boldText{
  color: #323232;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* CSS com o estilo da area de texto */
.textField{
  border-radius: 10px;
  border: 1px solid var(--Light-Gray, #EAEAEA);
}
/* CSS com o estilo do botão esqueceu a senha */
.linkedText{
  color: #878787;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 30px;
}
/* CSS com o estilo do botão esqueceu a senha */
.linkedText:hover{
  color: #FF6433;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 30px;
}
/* CSS com o estilo do botão de login */
.loginButton{
  border-radius: 17px;
  background: linear-gradient(117deg, #FF6433 -20.92%, #FFB44E 175.18%);
  box-shadow: 0px 4px 15px -4px #CBAFA2;
  width: 360px;
  height: 44px !important;
  color: #FFF;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* CSS com o estilo do texto para termos e condições */
.readConditionText{
  color:  #323232;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* CSS com o estilo do texto para acessar o termos e condições*/
.linkedConditionText{
  color: #323232;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
/* CSS com o estilo do texto ao passar o mouse para acessar o termos e condições*/
.linkedConditionText:hover{
  color: #FF6433;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.stamp {
  object-fit: contain;
  object-position: left;
  height: 30px;
}
.arxLogo {
  object-fit: contain;
  object-position: left;
  height: 40px;
}
</style>